@import "include/variables";
@import "../node_modules/bootstrap/scss/bootstrap";
html {
    scroll-behavior: smooth;
}

body {
    color: $text-color;
}

#sticky-cookie-policy-backdrop {
    background-color: rgba(0, 0, 0, 0.2);
}

.smart-first-div {
    margin-top: 70px;
    @include media-breakpoint-down(lg) {
        margin-top: 81px;
    }
    @include media-breakpoint-down(md) {
        margin-top: 70px;
    }
}

// DEV
.logged-in.admin-bar {
    .fixed-top {
        top: 32px;
        @include media-breakpoint-down(sm) {
            top: 46px;
        }
    }
}

.heart-underline {
    background: url(/wp-content/themes/completiamoci-wp-theme/img/heart.png);
    background-position: right bottom;
    background-repeat: no-repeat;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1.5rem;
}

#contacts-section {
    .contact-form {
        width: 300px;
    }
    span.wpcf7-not-valid-tip {
        color: $red-color;
    }
    .wpcf7-list-item {
        margin: 0;
        cursor: pointer;
    }
    .wpcf7-form {
        text-align: center;
    }
}

h3 {
    color: $red-color;
}





@import "include/utils";
@import "include/front-page";
@import "include/top-navbar";
@import "include/footer";
@import "include/page";
@import "include/404";
@import "include/landing";
@import "include/online-subscription";
@import "include/single";
