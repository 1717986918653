footer {
    background-color: $gray-color;
    .widget.widget_text {
        font-size: large;
        font-weight: bold;
        .textwidget {
            margin-top: 1rem;
            font-weight: normal;
            font-size: small;
        }
    }
    .credits-container {
        height: 3rem;
        background: darken($gray-color, 5%);
    }
    .fb-page {
        width: 300px;
    }
}