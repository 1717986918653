.home {
    h2 {
        .contrast {
            color: white;
            background: $red-color;
            padding-right: 0.5rem;
            padding-left: 0.5rem;
        }
    }
    .btn,
    button {
        padding-top: 0.40rem; //0.80rem;
        padding-bottom: 0.40rem; //0.80rem;
    }
    #stepFirst {
        .btn,
        button {
            padding-top: 0.80rem;
            padding-bottom: 0.80rem;
        }
    }
    .masthead {
        height: 90vh;
        background-image: url(/wp-content/themes/completiamoci-wp-theme/img/home/bg-9.jpg);
        min-height: 600px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-attachment: fixed;
        .animated {
            animation-duration: 0.5s !important;
        }
    }
    .logo {
        width: 250px;
        height: auto;
    }
    /* Rimozione branding google*/
    .pac-logo:after {
        display: none !important;
    }
    .chat-container {
        .consultant-chat {
            img,
            picture source {
                width: 70px;
                height: 70px;
            }
            .media-body {
                background: white;
                padding-left: 1rem;
                padding-right: 1rem;
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
                border-radius: .5rem;
                /*box-shadow: 0 6px 16px -3px rgba(107, 134, 177, .25);*/
                /*font-size: larger;*/
                &.first {
                    border-bottom-left-radius: 0;
                }
                &.middle {
                    border-bottom-left-radius: 0;
                    border-top-left-radius: 0;
                }
                &.end {
                    border-top-left-radius: 0;
                }
            }
        }
        .chat-box {
            display: none;
            width: 450px;
            //transition: all 0.5s ease-in-out;
            @include media-breakpoint-down(xs) {
                width: 300px;
            }
        }
    }
    #cityOkMsg,
    #invalidBirthDateMsg,
    #invalidMailMsg,
    #underageMsg,
    #dateOkMsg {
        display: none;
    }
    #bullets-section {
        .bullet {
            img,
            picture source {
                width: 150px;
                height: auto;
            }
        }
    }
    #events-section {
        .img-overflow {
            height: 300px;
            background-image: url(/wp-content/themes/completiamoci-wp-theme/img/home/bg-2.jpg);
            /*background-image: url('https://source.unsplash.com/BtbjCFUvBXs/1920x1080');*/
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            @include media-breakpoint-up(sm) {
                top: -2rem;
                bottom: -2rem;
                left: 0;
                right: 0;
                position: absolute;
                height: auto;
            }
        }
        .btn {
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }
    #testimonials-section {
        /* Vari stili per wp testimonials */
        .wtwp-testimonials-text em {
            font-style: normal !important;
        }
        .wtwp-avtar-image img {
            box-shadow: $box-shadow !important;
        }
        .wtwp-testimonials-wrp {
            margin-bottom: 0 !important;
        }
        .btn {
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }
    #match-section {
        .img-overflow {
            height: 300px;
            background-image: url(/wp-content/themes/completiamoci-wp-theme/img/home/bg-5.jpg);
            /*background-image: url('https://source.unsplash.com/BtbjCFUvBXs/1920x1080');*/
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            @include media-breakpoint-up(sm) {
                top: -2rem;
                bottom: -2rem;
                left: 0;
                right: 0;
                position: absolute;
                height: auto;
            }
        }
        .btn {
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }
}