// Blog

.post-list-even {
    justify-content: center;
    border: none;
    background: url(/wp-content/themes/completiamoci-wp-theme/img/heart.png);
    background-position: bottom;
    background-repeat: no-repeat;
    padding-bottom: 3rem;
    padding-left: 0rem;
    text-decoration: none;
    margin-bottom: 7rem;
    @include media-breakpoint-down(sm) {
        background-size: contain;
        padding-bottom: 2rem;
    }
}
.post-list-odd {
    justify-content: center;
    border: none;
    background: url(/wp-content/themes/completiamoci-wp-theme/img/heart2.png);
    background-position: bottom;
    background-repeat: no-repeat;
    padding-bottom: 3rem;
    padding-left: 0rem;
    text-decoration: none;
    margin-bottom: 7rem;
    @include media-breakpoint-down(sm) {
        background-size: contain;
        padding-bottom: 2rem;
    }
}
.card-block{
    a{
        text-decoration: none;
    }
    margin-bottom: 1rem;
}

.single {
    .banner-row {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 25rem;
    }
    #sidebar {
        //z-index: 1080;
        background: white;
    }
}

#postCol {
    background: white;
    @include media-breakpoint-down(sm) {
        border: none;
        margin-bottom: none;
    }
}

/*#imgCol {
    img{
        margin-left: 20px;
        margin-top: 10px;
        padding: 10px;
    }
}*/