.page-template-online-subscription-template, .page-template-online-subscription-payment-template {
    h2 {
        .contrast {
            color: white;
            background: $red-color;
            padding-right: 0.5rem;
            padding-left: 0.5rem;
        }
    }
    input.form-control, .custom-select, .custom-file-input, .custom-file-label {
        border-radius: 50px;
    }

    .form-check-label {
        cursor: pointer;
    }

    textarea.form-control {
        border-radius: 25px;
    }
    .form-section {
        margin-top: 2rem;
        margin-bottom: 1rem;
    }
    /*
    .fm-header-title {
        padding-bottom: 40px;
    }
    .fm-form-container.fm-theme1 {
        width: 100%;
        margin: 0 auto;
        .fm-form .fm-header {
            margin: 0 auto;
        }
        .wdform-required {
            color: $red-color;
        }
        .wdform_column {
            display: flex;
            flex-wrap: wrap;
        }
        .wdform-element-section {
            border-radius: 50px!important;
            margin: 0 auto;
            input[type="text"] {
                border-radius: 50px!important;
                padding: 6px 15px;
            }
        }
        select.wd-width-100,
        .wdform_select select {
            border-radius: 50px!important;
            -moz-appearance: none!important;
            -webkit-appearance: none!important;
            appearance: none!important;
            background-image: url("https://completiamoci.digipa.it/wp-content/uploads/2020/11/depositphotos_80596450-stock-illustration-down-arrow-vector-icon-4.jpg");
            background-position: right;
            background-repeat: no-repeat;
            background-size: 25px;
        }
        input#wdform_55_element6,
        input#wdform_56_element6,
        input#wdform_71_element6 {
            border-radius: 50px!important
        }
        div.checkbox-div.wd-choice.wd-flex.wd-flex-row {
            width: 120px;
            white-space: nowrap;
        }
        button {
            border: none;
            color: white;
            background-color: transparent;
            border-radius: 50px!important;
            margin: 0 auto;
        }
        button:focus {
            border: none
        }
        .wdform-element-section.wd-flex.btn.btn-pill.btn-success.w-25.p-3.text-center.wd-flex-row.wd-justify-content-left.wd-width-100 {
            display: flex;
            justify-content: center;
        }
        .wdform-matrix-cell {
            background-color: white!important;
            width: 3vw;
            padding: 0!important;
        }
        .checkbox-div {
            background-color: white!important;
            width: 3vw;
        }
        .wdform-matrix-column {
            background-color: white!important;
            width: 2vw;
        }
        .checkbox-div {
            width: 3vw
        }
        div.wdform-field.wd-width-100.wd-flex.wd-flex-row {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
        }
        .fm-form .StripeElement {
            border-radius: 50px!important;
        }
        .total-field div:nth-child(3),
        .total-field div:nth-child(4) {
            display: none;
        }
    }
    #wdform_42paypal_products6 {
        display: none;
    }
    #wdform_42paypal_tax6 {
        display: none;
    }
    #fm_stripe_name {
        .wdform-field {
            display: flex;
            flex-direction: row;
        }
    }
    .card_container {
        display: flex;
        flex-direction: row;
        align-items: center;
        .label_card {
            width: 24%;
            padding-left: 10px;
            box-sizing: border-box;
        }
    }
    .form-header {
        font-size: 20px;
        padding-bottom: 10px;
    }*/
}