/* UTILS */

.text-xxx-large {
    font-size: 2.5rem !important;
}

$texts: smaller,
small,
large,
x-large,
xx-large,
larger,
x-small,
xx-small,
medium;
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
        @each $value in $texts {
            .text#{$infix}-#{$value} {
                font-size: $value !important;
            }
        }
    }
}

@for $i from 1 through 10 {
    .delay-#{$i} {
        animation-delay: 0s + calc($i / 10);
    }
}

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
        @each $name,
        $value in $sizes {
            .w#{$infix}-#{$name} {
                width: $value !important;
            }
        }
        @each $name,
        $value in $sizes {
            .h#{$infix}-#{$name} {
                height: $value !important;
            }
        }
    }
}

.img-fluid-fix {
    object-fit: cover;
    width: 100%;
    height: auto;
}

.backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.15);
    pointer-events: none;
}

@mixin overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.no-events {
    pointer-events: none;
}

.auto-events {
    pointer-events: auto;
}

ul.styled-list {
    padding-left: 0;
    margin-bottom: 0;
    li {
        margin: 0;
        padding-top: 0.1rem;
        padding-bottom: 0.1rem;
        padding-left: 1.3rem;
        padding-right: 0.5rem;
        list-style: none;
        background-image: url("/wp-content/themes/welldone-website-custom-theme/assets/dot.png");
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 0.8rem;
        text-transform: uppercase;
        font-size: large;
    }
}

.visibility-hidden {
    visibility: hidden !important;
}

.visibility-collapsed {
    visibility: collapse !important;
}

.visibility-visible {
    visibility: visible !important;
}