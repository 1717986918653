.page {
    .banner-row {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 25rem;
    }
    #sidebar {
        //z-index: 1080;
        background: white;
    }
}