.page-template-landing-template {
    h2 {
        .contrast {
            color: white;
            background: $red-color;
            padding-right: 0.5rem;
            padding-left: 0.5rem;
        }
    }
    #bullets-section {
        .bullet {
            img,
            picture source {
                width: 150px;
                height: auto;
            }
        }
    }    
    #match-section {
        .img-overflow {
            height: 300px;
            background-image: url(/wp-content/themes/completiamoci-wp-theme/img/bg-landing.jpeg);
            /*background-image: url('https://source.unsplash.com/BtbjCFUvBXs/1920x1080');*/
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            @include media-breakpoint-up(sm) {
                top: -2rem;
                bottom: -2rem;
                left: 0;
                right: 0;
                position: absolute;
                height: auto;
            }
        }
        .btn {
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }
    #button-section{
        a{
            width:2rem;
        }
    }
}