$gray-color: #f0f0f0;
$red-color: #c5191a;
$blue-color: #3155ab;
$blue-alt-color: #004c98;
$text-color: black;
$theme-colors: ( "primary": $blue-color, "secondary": $red-color);
$link-color: $red-color;
$font-family-sans-serif: "Roboto",
sans-serif !default;
$font-family-serif: Georgia,
"Times New Roman",
Times,
serif !default;
$font-family-monospace: Menlo,
Monaco,
Consolas,
"Liberation Mono",
"Courier New",
monospace !default;
//$font-family-base: $font-family-sans-serif !default;
$grid-gutter-width: 0 !default;
$sizes: (85: 85%);
$spacer: 1rem;
$spacers: (6: ($spacer * 6), 7: ($spacer * 7), 8: ($spacer * 8), 9: ($spacer * 9), 10: ($spacer * 10));
$navbar-padding-y: 0.3rem;
$navbar-nav-link-padding-x: 1rem;
$alert-border-radius: 1.6rem;