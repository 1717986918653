#top-navbar {
    .navbar-brand img {
        height: 50px;
    }
    .navbar-nav .nav-item {
        font-weight: bold;
        /*text-transform: uppercase;*/
    }
    .menu-nav {
        background: url('/wp-content/themes/completiamoci-wp-theme/img/heart.png');
        background-position: right bottom;
        background-repeat: no-repeat;
        padding-bottom: 5px;
        @include media-breakpoint-up(md) {
            padding-left: 20px;
            padding-right: 30px;
        }
    }
    .contacts-nav {
        /*font-size: smaller;*/
    }

    .navbar-toggler {
        border: 0;
    }

}